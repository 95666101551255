@import "vendor/bootstrap/5.3.3/scss/bootstrap";
@import "vendor/bootstrap/icons-1.11.3/font/bootstrap-icons.scss";

// VARIABLES //////////////////////////////////////////////////////////

:root {
  --color-white: #FFF;
  --color-light: #F3F7F6;
  --color-light-subdued: #BAC1BF;
  --color-black: #000;
  --color-grey-light: #CFD3DD;
  --color-dark: #1E2025;
  --color-blue: #4D70CA;
  --color-blue-dark: #252932;

  --expo-accent-color: #489F2A;
  --expo-accent-bg: #88C870;

  --body-color: var(--color-dark);
  --body-bg: var(--color-light);

  --link-color: var(--expo-accent-color);
  --link-color-hover: var(--color-dark);

  --border-color: var(--color-grey-light);

  --font-family-headings: "Poppins", arial, sans-serif;
  --font-family-text: "Inter", arial, sans-serif;

  --box-shadow: 5px 5px 5px rgb(25,56,66,25%);
  --box-shadow-vert: 0 5px 5px rgb(25,56,66,25%);

  --fs-lg: 3rem;
  --fs-xlg: 3.5rem;

  --nav-main-small-height: 70px;
  --nav-main-large-height: 85px;
}

// BASIC //////////////////////////////////////////////////////////

body {
  //padding-top: 160px;
  font-size: 1rem;
  font-family: Inter, arial, sans-serif;
  color: var(--body-color);
  background-color: var(--body-bg);
}

// BREAKPOINTS //////////////////////////////////////////////////////////
// md up
@include media-breakpoint-up(md) {
  body {
    font-size: 1.065rem;
  }
}
// lg up
@include media-breakpoint-up(lg) {
  body {
    
  }
}
// xl up
@include media-breakpoint-up(xl) {
  body {
    font-size: 1.125rem;
  }
}

::selection {
  background-color: var(--expo-accent-bg);
  color: var(--color-dark);
}

a {
  border-bottom: 1px solid var(--link-color);
  text-decoration: none;
  color: var(--link-color);
}
  a:hover, a:active, a:focus {
    color: var(--link-color-hover);
    border-color: var(--link-color-hover);
  }
  main a {
    font-weight: 500;
    &:active, &:focus {
      outline: 1px dashed var(--link-color-hover);
    }
  }

blockquote {
  position: relative;
  margin: 1.5rem 0 0;
  padding: .5rem 3rem;
  //quotes: "\201C""\201D""\2018""\2019";
  font-size: 1rem;
}
  blockquote:before {
    content: "\201C";
    position: absolute;
    top: 2rem;
    left: 0;
    margin-right: 0.25em;
    line-height: 0.1em;
    vertical-align: -0.4em;
    font-size: 4em;
    font-family: Times, serif;
  }
    blockquote cite {
      display: block;
      font-style: normal;
    }

@include media-breakpoint-up(xl) {
  blockquote {
    font-size: 1.25rem;
  }
}

table,
.table {
  margin: 2rem 0;
  border-color: var(--bs-table-border-color);
  border: 1px solid var(--border-color);
  
  tr {
    th,
    td {
      padding: .75rem 1rem;
      font-size: 1rem;
    }
  }
}

// HELPERS //////////////////////////////////////////////////////////

.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.expo-accent-color {
  color: var(--expo-accent-color);
}

.inverted-colors {
  background-color: var(--color-blue-dark);

  &,
  p, 
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--color-light);
  }
}
  .inverted-colors a:not(.button) {
    border-color: var(--color-light);
    color: var(--color-light);
    &:hover, &:active, &:focus {
      border-color: var(--expo-accent-color);
      color: var(--expo-accent-color);
    }
    &:active, &:focus {
      //outline: 1px dashed var(--expo-accent-color);
    }
  }


// SECTIONS //////////////////////////////////////////////////////////

.expo-section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  .container {
    position: relative;
  }
}
  .expo-section-head {
    /*border-left: 5px solid var(--expo-accent-color);
    
    padding: 1.5rem;
    text-align: left;*/
    //margin-left: -1.8125rem;
    //max-width: 700px;
    margin-bottom: 3rem;
  }

.expo-cta-section {
  //background-color: var(--expo-accent-bg);
  text-align: left;
}

// BREAKPOINTS
@include media-breakpoint-up(sm) {
  .expo-section {
    padding: 3rem 0;
  }
}

@include media-breakpoint-up(md) {
  .expo-section {
    padding: 4rem 0;
  }
}

@include media-breakpoint-up(lg) {
  .expo-section {
    padding: 5rem 0;
  }
  .expo-cta-section {
    text-align: center;
    .expo-intro {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@include media-breakpoint-up(xl) {
  .expo-section {
    padding: 6rem 0;
  }
  .container-narrow {
    max-width: 930px;
  }
}

@include media-breakpoint-up(xxl) {
    
}

// Narrow devices
@media (min-width: 1800px){
  /*.expo-section {
    .container {
      display: flex;
    }
  }
  .expo-section-head {
    border-left: none;
    border-right: 5px solid var(--expo-accent-color);
    text-align: right;
    width: 50%;
  }
    .expo-customer-story-card {
      width: 50%;
      & > [class^="col"] {
        width: 100%;
      }
    }*/
}

// BOOTSTRAP
.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x)* 0.5);
  padding-left: calc(var(--bs-gutter-x)* 0.5);
}
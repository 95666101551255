/////////////////////////////////////////////////////////////////////
// BUTTONS //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

.button,
a.button {
  cursor: pointer;
  //display: inline-block;
  //vertical-align: middle;
  display: block;
  box-shadow: var(--box-shadow);
  margin: 1rem 0;
  border: 1px solid var(--border-color);
  border-radius: 11px;
  text-align: center;
  line-height: 1;
  user-select: none;
  padding: .75rem 1.75rem;
  background-color: (var(--color-white));
  font-family: var(--font-family-text);
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--color-dark);
  transition: color .1s ease-in-out, background-color .1s ease-in-out, border-color .1s ease-in-out, box-shadow .1s ease-in-out;
}

// BREAKPOINTS
@include media-breakpoint-up(sm) {
  .button,
  a.button {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-up(md) {
  .button,
  a.button {
    display: inline-block;
  }
}

@include media-breakpoint-up(lg) {
  .button,
  a.button {
    font-size: 1.37rem;
  }
}

@include media-breakpoint-up(xl) {
  .button,
  a.button {
    font-size: 1.5rem;
  }
}

// PRIMARY
.button-primary,
a.button-primary {
  border: 1px solid var(--color-dark);
  color: var(--color-white);
  background-color: (var(--color-dark));

  &:hover, &:active, &:focus {
    border: 1px solid var(--border-color);
    color: var(--color-dark);
    background-color: var(--color-white);
  }
}

// SECONDARY
.button-secondary,
a.button-secondary {
  color: var(--color-dark);
  background-color: var(--color-white);

  &:hover, &:active, &:focus {
    border: 1px solid var(--color-dark);
    color: var(--color-white);
    background-color: var(--color-dark);
  }
}

// SMALL
.button-small,
a.button-small {
  border-radius: 7px;
  padding: .65rem 1.25rem;
  font-size: 1rem;
}

// LARGE
/*.button-large,
a.button-large {
  display: block;
}*/
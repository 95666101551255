////////////////////////////////////////////////////////////////////////
// SITE ////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

// HEADER
.site-header {
  box-shadow: var(--box-shadow);
}

// FOOTER
.site-footer {
  line-height: 1.6;
  text-align: center;
  font-size: .9rem;
  .left {
    margin-bottom: 1rem;
  }
}

// BREAKPOINTS /////////////////////////////////////////////////////////

// lg up
@include media-breakpoint-up(md) {

  // FOOTER
  .site-footer {
    .left-and-right {
      display: flex;
      justify-content: space-between;
      text-align: left;
      .left {
        margin-bottom: 0;
      }
      .right {
        text-align: right;
      }
        ul {
          display: flex;
          li:not(first-child) {
            margin-left: .5rem;
          }
        }
    }
  }
}
/////////////////////////////////////////////////////////////////////////
// TYPOGRAPHY ///////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////

// <H>
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  //max-width: 55rem;
  margin-bottom: .75rem;
  font-family: Poppins, arial, sans-serif;
  font-weight: 700;
  color: var(--color-dark);
}


// INTRO
.expo-intro {
  max-width: 60ch;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}
  .text-center {
    /*h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6,*/
    .expo-intro {
      margin-left: auto;
      margin-right: auto;
    }
  }

// SUB HEADING
.expo-sub-heading {
  margin-bottom: .35em;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
}

// BREAKPOINTS //////////////////////////////////////////////////////////
// md up
@include media-breakpoint-up(md) {

  // INTRO
  .expo-intro {
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
  }
  
  // SUB HEADING
  .expo-sub-heading {
    //font-size: 1.125rem;
  }
}
// lg up
@include media-breakpoint-up(lg) {

  // INTRO
  .expo-intro {
    margin-bottom: 3rem;
    font-size: 1.325rem;
  }
}
// xl up
@include media-breakpoint-up(xl) {

  // INTRO
  .expo-intro {
    font-size: 1.5rem;
  }
}
/////////////////////////////////////////////////////////////////////////
// VCARD ////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////

.page-vcard {
  padding-top: 0;
}
  .section-vcard {
    padding: 2rem 1rem;

    img {
      width: 100%;
      //box-shadow: 0 0 0 2px var(--border-color);
    }

    .expo-intro {
      margin-bottom: 1rem;
    }

    .expo-sub-heading {
      margin-bottom: 2rem;
    }

    .button {
      box-shadow: none;
    }
  }
    .section-vcard__content {
      text-align: center;
    }

// BREAKPOINTS //////////////////////////////////////////////////////////
// md up
@include media-breakpoint-up(md) {
  .section-vcard {
    padding: 3rem 2.4rem;
  }
    .section-vcard__content {
      padding-left: 2rem;
      text-align: left;
    }
}
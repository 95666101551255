/////////////////////////////////////////////////////////////////////////
// ANIMATIONS ///////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
.jiggle-me {
  display: inline-block;
}

// Show animation 1 time with a small delay for non-hover devices
/*@media (hover: none){
  
}*/
.expo-splash__content h1 {
  .jiggle-me {
    animation: jiggly 5s ease-in-out .35s;
  }
}
// Show animation everytime when hovering on hover devices
@media (hover: hover) {
  //.expo-splash__content h1,
  .site-footer {
    &:hover {
      .jiggle-me {
        animation: jiggly 5s ease-in-out;
      }
    }
  }
}

@keyframes jiggly {
  0% {
    transform: skewX(0) translateX(0) translateZ(0); // Tricks browser to use GPU instead, lowering CPU load on animations
  }
  4% {
    transform: skewX(12deg) translateX(-1.5rem) translateZ(0);
  }
  7% {
    transform: skewX(20deg) translateX(-1.75rem) translateZ(0);
  }
  9% {
    transform: skewX(-15deg) translateX(1.5rem) translateZ(0);
  }
  12% {
    transform: skewX(10deg) translateX(-.5rem) translateZ(0);
  }
  14% {
    transform: skewX(-8deg) translateX(.35rem) translateZ(0);
  }
  16% {
    transform: skewX(4deg) translateX(-.20rem) translateZ(0);
  }
  17% {
    transform: skewX(-2deg) translateX(.1rem) translateZ(0);
  }
  18% {
    transform: skewX(0) translateX(0) translateZ(0);
  }
}

main { 
  animation: fadeInAnimation ease .65s; 
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.expo-splash__image {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
    opacity: 1; 
  } 
}
////////////////////////////////////////////////////////////////////////
// EXPO CARDS //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

// WITH ICON HORIZONTAL
.expo-card-w-icon-horiz {
  align-items: start;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  i {
    padding-right: 1.5rem;
    line-height: 1;
    font-size: 3rem;
  }
  button, 
  .button {
    margin-top: 0;
  }
}

// WITH IMAGE VERTICAL
.expo-card-w-image-vert {
  margin-top: 2rem;
  margin-bottom: 2rem;
  img {
    margin-bottom: 1.25rem;
  }
}

// CUSTOMER STORY
.expo-customer-story-card {
  margin-top: 4rem;
  margin-bottom: 4rem;

  img {
    margin-bottom: 3rem;
  }
}


// BREAKPOINTS /////////////////////////////////////////////////////////

// Custom BP only to hide icons on smaller devices not covered by Bootstrap
@media (max-width:400px) {
  // WITH ICON HORIZONTAL
  .expo-card-w-icon-horiz {
    i {
      //display: none;
    }
  }
}

// md up
@include media-breakpoint-up(md) {

  // WITH ICON HORIZONTAL
  .expo-card-w-icon-horiz {
    i {
      font-size: 4rem;
    }
  }

  // WITH IMAGE VERTICAL
  .expo-card-w-image-vert {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

// lg up
@include media-breakpoint-up(lg) {

  // WITH ICON HORIZONTAL
  .expo-card-w-icon-horiz {
    margin-top: 2rem;
    margin-bottom: 2rem;
    i {
      font-size: 4.5rem;
    }
  }
    // SPECIFIC LAYOUT
    .expo-features .expo-card-w-icon-horiz {
      padding-right: 3rem;
    }

  // WITH IMAGE VERT
  .expo-card-w-image-vert {
    /*margin-top: 4rem;
    margin-bottom: 4rem;*/
    img {
      width: 100%;
      //height: 400px;
      object-fit: cover;
      //padding-right: 1.5rem;
    }
  }
    // SPECIFIC LAYOUT
    .expo-solutions .expo-card-w-image-vert {
      img {
        height: 400px;
      }
    }

  .expo-card-w-image-vert.flex-row-reverse {
    img {
      //padding-left: 1.5rem;
    } 
  }

  // CUSTOMER STORY
  .expo-customer-story-card {
    margin-top: 7rem;
    margin-bottom: 7rem;

    img {
      min-height: 400px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 0;
      padding-right: 1.5rem;
    }
  }
}

// xl up
@include media-breakpoint-up(xl) {

  // WITH ICON HORIZONTAL
  .expo-card-w-icon-horiz {
    margin-top: 3rem;
    margin-bottom: 3rem;
    
    i {
      //padding-right: 2.5rem;
      //font-size: 6rem;
    }
  }
    // SPECIFIC LAYOUT
    .expo-features .expo-card-w-icon-horiz {
      padding-right: 4rem;
    }

  // WITH IMAGE HORIZONTAL
  .expo-card-w-image-vert {

  }

  // CUSTOMER STORY
  .expo-customer-story-card {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}

// xxl up
@include media-breakpoint-up(xxl) {

  // CUSTOMER STORY
  .expo-customer-story-card {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
}
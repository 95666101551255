////////////////////////////////////////////////////////////////////////
// PAGES ///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

// GET IN TOUCH
.page-get-in-touch {
  .expo-cta-section {
    .button-secondary {
      display: none;
    }
  }
}

// SITEMAP
.page-sitemap {

  ul {
    color: var(--color-light-subdued);
    list-style-type: none;
    padding-left: 0;

    a:not(.button) {
      border: none;
     color: var(--color-light-subdued); 
    }
  }
  
}

/////////////////////////////////////////////////////////////////////////
// EXPO SPLASH //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////

.expo-splash {
  position: relative;
  //margin: 0 0 3rem 0;
  padding: 0 0 2rem;
  background: rgb(218,245,238);
  background: linear-gradient(109deg, rgba(218,245,238,1) 0%, rgba(239,251,248,1) 10%, rgba(243,247,246,1) 100%);
}
  .expo-splash h1 {
    font-size: 2.5rem;
  }
  .expo-splash .button,
  .expo-splash a.button {
    display: block;
    //margin-bottom: 1rem;
  }

  .expo-splash__image {
    position: relative;
    text-align: center;
    img {
      width: 100%; 
      max-width: 409px;
    }
  }



  /*.expo-splash__content {
    h1,
    p {
      text-shadow: 10px 10px 5px rgba(25, 56, 66, 0.15)
    }
  }*/


// BREAKPOINTS //////////////////////////////////////////////////////////

// md down
@include media-breakpoint-down(md) {
  .expo-splash {
    //flex-direction: column-reverse;
    padding-top: 1rem;
  }
    .expo-splash__content {
      order: 2;
    }
    .expo-splash__image {
      order: 1;
      overflow: hidden;
      height: 400px;
      margin-top: 1rem;

      /*.img-default {
        display: none;
      }
      .img-flipped {
        display: inline-block;
      }*/
    }
    .expo-splash__image:after {
      content: "";
      display: block;
      position: absolute;
      height: 280px;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(243, 247, 246, 0), rgba(243, 247, 246, 1));
      bottom: 0;
    }
}

// sm down
@include media-breakpoint-down(sm) {
  
  .expo-splash__image {
    height: 320px;
    max-height: 45vh;
  }
    .expo-splash__image:after {
      height: 180px;
    }
      .expo-splash__image img {
        width: 75%;
        min-width: 260px;
      }
}

// md up
@include media-breakpoint-up(md) {
  .expo-splash {
    //margin: 0 0 3rem 0;
    padding: 3rem 0;
    min-height: calc(100vh - 85px);

    h1 {
      font-size: 2.75rem;
    }

    /*&:hover {
      .expo-splash__image:after {
        //transform:translateY(400px);
      }
    }*/
  }

  .expo-splash__content {
    padding-right: 1rem;
  }
  .expo-splash__image {
    overflow: hidden;
    text-align: left;

    &:after {
      /*content: "";
      display: block;
      position: absolute;
      height: 66%;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(243, 247, 246, 0), rgba(243, 247, 246, 1));
      bottom: 0;
      //transition: transform .5s cubic-bezier(.17,.67,.89,.58);*/
    }

    /*.img-default {
      display: inline-block;
    }
    .img-flipped {
      display: none;
    }*/
  }
  .expo-splash__image:hover {
    &:after {
      //transform:translateY(400px);
    }
  }
}

// lg up
@include media-breakpoint-up(lg) {
  .expo-splash {
    h1 {
      margin-bottom: 1.5rem;
    }
  }
  .expo-splash__image {
    img {
      //max-height: 80vh;
      width: 100%;
    }
  }
}

// xl up
@include media-breakpoint-up(xl) {
  .expo-splash__content {
    padding-right: 0;
  }
  .expo-splash h1 {
    font-size: 3.5rem;
  }
  .expo-splash p {
    margin-right: 10%;
  }
  .expo-splash .button,
  .expo-splash a.button {
    display: inline-block;
    margin-right: .5rem;
  }
  
}

// xxl up
@include media-breakpoint-up(xxl) {
  .expo-splash h1 {
    font-size: 4rem;
  }
}

// "Small" devices
@media (max-height: 800px){
  .expo-splash__image {
    img {
      height: 600px;
      width: auto;
    }
  }
}

// For high res portrait mode, e.g. an Asus Zenbook Fold we might want to show more info above the fold (haha, no pun intended)
@media (min-height: 1500px){
  .expo-splash {
    padding: 3rem 0;
    min-height: calc(80vh - 85px);
  }
}
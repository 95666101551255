////////////////////////////////////////////////////////////////////////
// NAVIGATION //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

// BOOTSTRAP
body {
  padding-top: var(--nav-main-small-height);
}
  // NAVBAR
  .navbar {
    height: var(--nav-main-small-height);
    box-shadow: var(--box-shadow-vert);

    a:not(.button) {
      color: var(--color-dark);
      &:hover, &:active, &:focus {
        color: var(--expo-accent-color) !important;
      }
    }
  }
    // LOGO
    .navbar-brand {
      border: none;
      img {
        width: 80px;
      }
    }
      // TOGGLE NAV BUTTON
      .navbar-button.navbar-toggler {
        &,
        &:focus, &:active {
          outline: none;
          box-shadow: none;
          border-color: transparent;
          padding: 0 10px;
          line-height: 1rem;
          font-size: 2rem;
          background: transparent;
        }

        .bi.bi-list {
          display: none;
        }
        .bi.bi-x {
          display: inline;
        }
        &.collapsed {
          .bi.bi-list {
            display: inline;
          }
          .bi.bi-x {
            display: none;
          }
        }
      }

      // NAV LVL 1
      .navbar-collapse {
        box-shadow: var(--box-shadow);
      }
        .navbar-nav {
          width: 100%;
        }
          // NAV ITEM
          .nav-item {
            margin: 0 .25rem;
          }
            // NAV LINK
            .nav-link {
              &:after {
                transition: transform .25s ease-in-out;
              }
              
              &.show {
                &:after {
                  transform: rotate(180deg);
                }
              }
            }
            .dropdown-toggle:after {
              margin-left: .05em;
            }
              // NAV LVL 2
              .navbar-expand-lg .navbar-nav .dropdown-menu {
                margin: 0;
                // NAV LVL 2 LINK
                a {
                  margin: .25rem 0;
                }
              }


// BREAKPOINTS //////////////////////////////////////////////////////////
// sm down
@include media-breakpoint-down(sm) {}

// md down
@include media-breakpoint-down(md) {
  .navbar { 
    transition: top 0.3s linear;
  }
}

// md up
@include media-breakpoint-up(md) {
  
}
// lg down
@include media-breakpoint-down(lg) {
  .navbar > .navbar-content {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
    .navbar-brand {
      margin-left: calc(var(--bs-gutter-x) * 0.5);
    }
    .navbar-toggler {
      margin-right: calc(var(--bs-gutter-x) * 0.5);
    }
    .navbar-collapse {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      max-height: calc(100vh - var(--nav-main-small-height));
      //padding: 0 1rem;
      padding: 0;
      text-align: center;
      background-color: var(--color-white);

      &.show {
        overflow-y: auto;
      }
    }

      .navbar-expand-lg .navbar-nav .nav-item {
        border-bottom: 1px solid var(--border-color);
        &:first-child {
          border-top: 1px solid var(--border-color);
        }
      }
        .nav-link {
          padding: 1rem;
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
          border: none;
          //border-top: 1px solid var(--border-color);
          border-radius: 0;
          text-align: center;
          //background: #f5f5f5;

            a {
              display: block;
              margin: 1rem 0;
              border: none;
              //text-transform: uppercase;
              font-size: 1rem;
              //color: var(--color-dark);
            }
            .sub-link-tease {
              display: none;
            }
        }
}

/*@media (max-height: 360px){
  .navbar-collapse {
    
  }
}*/

// lg up
@include media-breakpoint-up(lg) {
  body {
    padding-top: var(--nav-main-large-height);
  }
    .navbar {
      height: var(--nav-main-large-height);
    }
      .navbar-brand img {
        width: 100px;
      }
      .navbar-collapse {
        box-shadow: none;
        text-align: left;
      }
        .navbar-nav {
          width: auto;
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
          width: 100%;
          margin-top: 0;
          box-shadow: var(--box-shadow);
          border: none;
          border-radius: 0;
          padding: 1rem 0 2rem;
          //border-top: 1px solid var(--border-color);

          ul {
            border-left: 3px solid var(--expo-accent-bg);
            padding: .75rem 1.5rem;
            //padding: 0 1rem .5rem calc(1rem + 113px); // BAD, I KNOW :/
          }
            li {
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
            }
              a {
                display: block;
                border: none;
                //text-transform: uppercase;
                font-size: 1.1rem;
                color: var(--color-dark);
              }
        }
}
// xl up
@include media-breakpoint-up(xl) {
  /*.navbar-brand img {
    width: 140px;
  }*/
}
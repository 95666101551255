////////////////////////////////////////////////////////////////////////
// EXPO CUSTOMER LOGOS /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

.expo-customer-logotype-list {

}
  .expo-customer-logotype-list li {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: .75rem;
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    background-color: var(--color-white);
  }
    .expo-customer-logotype-list a {
      display: block;
      border: none;
      padding: 2rem;
      transition: opacity .1s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
      .expo-customer-logotype-list img {
        width: auto;
        height: 30px;
      }

// BREAKPOINTS /////////////////////////////////////////////////////////

// sm down
@include media-breakpoint-down(sm) {

}

// lg up
@include media-breakpoint-up(lg) {
  .expo-customer-logotype-list img {
    height: 40px;
  }
}

